import React, { useEffect, useMemo, useState } from 'react';
import { Button, ColorPicker } from 'antd';
import type { ColorPickerProps, GetProp } from 'antd';

type Color = Extract<GetProp<ColorPickerProps, 'value'>, string | { cleared: any }>;

const ColorPick = ({propagateColor, label, defaultColor}: any) => {
  const [color, setColor] = useState<Color>('rgb(255,255,255)');

  const bgColor = useMemo<string>(
    () => {
      const hexColor = typeof color === 'string' ? color : color!.toRgbString();
      propagateColor(hexColor);
      return hexColor;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [color],
  );

  useEffect(() => {
    if (defaultColor) {
      setColor(defaultColor);
    }
  }, [defaultColor])

  const btnStyle: React.CSSProperties = {
    backgroundColor: bgColor,
  };

  return (
    <ColorPicker value={color} onChange={setColor}>
      <Button type="primary" style={btnStyle}>
        {label}
      </Button>
    </ColorPicker>
  );
};

export default ColorPick;