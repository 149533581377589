import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { POST_INPUTS } from '../../constants';
import {
  MDBBtn,
  MDBCol,
  MDBCollapse,
  MDBIcon,
  MDBRow,
} from 'mdb-react-ui-kit';
import { ApiClient } from '../../api_client';
import './form_builder.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserId, updateUserTokens } from "../../store/user.reducer";
import { useTranslation } from 'react-i18next';
import FieldsCreator from '../fields_creator/fields_creator';
import TopicsList from '../topics_list/topics_list';

const FormBuilder = (props: any) => {

  const userId = useSelector(getUserId);
  const user = useSelector(getUser);
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [formData, setFormData]: [any, any] = useState({});
  const [topic, setTopic]: [any, any] = useState('');
  const [pendingRequest, setPendingRequest]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const toggleShow = () => setShowAdvancedSettings(!showAdvancedSettings);
  const postsInputs: any = POST_INPUTS;

  useEffect(() => {
    // Reset form data upon topic change
    setFormData((data: any) => {
      const {mainFields, ...cleanedData} = data;
      return {
        ...cleanedData,
        topic
      }
    });
  }, [topic]);

  useEffect(() => {
    setTopic(user.profile?.topic);
    updateInputData('outputPostLanguage', user.profile?.outputPostLanguage, 'advancedFields');
  }, [user?.profile]);

  useEffect(() => {
    setFormData((data: any) => ({
        ...data,
        socialAccounts: props.socialAccounts,
      })
    )
  }, [props.socialAccounts]);

  const updateInputData = (name: string, value: any, fieldGroup: string, isSensitive: boolean = false) => {

    // If value is empty, completely remove the field from formData state
    setFormData((data: any) => {
      if (value === '') {
        delete data[fieldGroup][name]
        return {...data};
      } else {
        return {
          ...data,
          [fieldGroup]: {
            ...data[fieldGroup],
            [name]: {
              value,
              isSensitive
            }
          }
        }
      }
    })
  }

  const handleInputChange = (event: any, fieldGroup: string, isSensitive: boolean = false) => {
    const {name, value} = event.target;
    updateInputData(name, value, fieldGroup, isSensitive);
  };

  const handleSubmit = (event: any) => {
    // TODO Refactor this

    const {topic, socialAccounts, mainFields, advancedFields} = formData;
    // Remove brand related fields
    const { brandLogo, primaryColor, secondaryColor, ...filteredAdvancedFields } = advancedFields;
    console.log(formData)
    props.pendingRequest(true);
    setPendingRequest(true);
    ApiClient.generatePost({
      user: userId,
      topic,
      socialAccounts: Object.keys(socialAccounts).filter(key => socialAccounts[key]),
      ...mainFields,
      ...filteredAdvancedFields
    }).then((response: any) => {
      if (response.data?.post == null) {
        handlePostGenerationError(null);
      }
      dispatch(updateUserTokens({
        tokens: response?.data?.remainingTokens,
      }))
      props.updateGeneratedMessage(response?.data);
    })
      .catch((error) => {
        const data = error.response?.data;
        handlePostGenerationError(data.errorCode);
      })
      .finally(() => {
        props.pendingRequest(false);
        setPendingRequest(false);
      });
    event.preventDefault();
  };

  const handlePostGenerationError = (errorCode: string | null) => {
    let errorMessage = '';
    switch (errorCode) {
      case 'TRIAL_EXPIRED':
        errorMessage = t('shared.errors.trialExpired');
        break;
      case 'NO_MORE_TOKENS':
        errorMessage = t('shared.errors.tokensExpired');
        break;
      case 'MEMBERSHIP_EXPIRED':
        errorMessage = t('shared.errors.membershipExpired');
        break;
      default:
        errorMessage = t('shared.errors.unexpected');
    }
    toast.error(errorMessage);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <MDBRow className="row-cols-sm-2 mt-4">
          <MDBCol>
            <TopicsList propagateTopic={setTopic}/>
          </MDBCol>
          <MDBCol>
            <select className="w-100" name="outputPostLanguage"
                    defaultValue=""
                    value={formData.advancedFields?.outputPostLanguage?.value || ''}
                    onChange={(event) => {
                      handleInputChange(event, 'advancedFields')
                    }}>
              <option value="" disabled>
                {t('newPost.chooseLanguage')}
              </option>
              <option value="english">{t(`shared.english`)}</option>
              <option value="french">{t(`shared.french`)}</option>
              <option value="arabic">{t(`shared.arabic`)}</option>
              <option value="spanish">{t(`shared.spanish`)}</option>
              <option value="german">{t(`shared.german`)}</option>
              <option value="italian">{t(`shared.italian`)}</option>
            </select>
          </MDBCol>
        </MDBRow>
        {
          topic &&
					<>
						<MDBRow className="row-cols-sm-2">
              {<FieldsCreator fields={postsInputs.mainFields[topic]} fieldsGroup='mainFields'
                              propagateFormData={(data: any) => setFormData((d: any) => ({...d, ...data}))}
                              propagateInputData={updateInputData}
              />}
						</MDBRow>

						<div className="more-settings mt-4" onClick={toggleShow}>
							<MDBBtn size="sm" type="button" color="tertiary" outline className="py-2 bg-white">
                {t('newPost.advancedSettings')}
							</MDBBtn>
						</div>

						<MDBCollapse show={showAdvancedSettings} className="h-auto">
							<MDBRow className="row-cols-sm-2">
								<FieldsCreator fields={postsInputs.advancedFields} fieldsGroup='advancedFields'
								               propagateFormData={(data: any) => setFormData((d: any) => ({...d, ...data}))}
								               propagateInputData={updateInputData}/>
							</MDBRow>
						</MDBCollapse>
					</>
        }
        <MDBRow className="row-cols-sm-2 mt-4">
          <MDBCol>
            <MDBBtn id="generate-post" className="btn-with-loader mx-auto w-100 fw-bold text-center" size="lg"
                    color="primary"
                    disabled={pendingRequest || !topic || Object.keys(props.socialAccounts).filter(key => props.socialAccounts[key]).length === 0}>
              {pendingRequest ? t('newPost.buttonPendingGeneratePost') : t('newPost.buttonGeneratePost')}
              {pendingRequest &&
								<div className="loader-icon fs-5">
									<MDBIcon fas icon="circle-notch"/>
								</div>
              }
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </form>
    </>
  );
};

export default FormBuilder;